<template>
    <Page title="Dummy Sample Report" icon="mdi-checkbox-marked-outline">
        <p>Invoice</p>
        <run-report request-type="download" :run-items="runInvoice" /> <br />
        <run-report request-type="email" :run-items="runInvoice" :options="emailOptions" /> <br />
        <run-report request-type="print" :run-items="runInvoice" /> <br />
        <p>Remittance</p>
        <smart-form v-model="formModel" :schema="formSchema" :hide-border="true" />

        <run-report
            request-type="download"
            :run-items="runRemittance"
            filename="remittance.pdf"
            running-title="Download Remittance"
        />
        <br />
        <run-report
            request-type="email"
            :run-items="runRemittance"
            :options="emailOptions"
            running-title="Email Remittance"
        />
        <br />
        <run-report
            request-type="print"
            :run-items="runRemittance"
            running-title="Print Remittance"
        />
        <br />
    </Page>
</template>
<script>
export default {
    data() {
        return {
            formModel: {
                billPaymentRunId: '62566b6b126ff87e01a06697',
                vendorId: '603862cb43431592f1da79e4',
            },
            formSchema: {
                billPaymentRunId: {
                    component: 'TextField',
                    label: 'Bill Payment Id',
                    cols: 7,
                    clearable: true,
                },
                vendorId: {
                    component: 'TextField',
                    label: 'Vendor Id',
                    cols: 6,
                    clearable: true,
                },
            },
            runInvoice: [
                {
                    reportName: 'invoice',
                    reportParams: {
                        documentId: '612d7bf3bf11a8000a708538', //from the document collection
                    },
                },
                {
                    reportName: 'alexs report',
                    reportParams: {
                        customerId: '5f1f61c635548937e35ba0ab',
                    },
                },
            ],
            emailOptions: {
                reportFileName: 'remittance.pdf',
                email: {
                    from: 'Payables payables@standarduniversal.com.au',
                    to: [
                        'Brian Austin brian.austin@standarduniversal.com.au',
                        'Alex Bieniawski alex.bieniawski@standarduniversal.com.au',
                    ],
                    subject: 'test email',
                    text: 'text message',
                    html: '<h1> html text message</h1><br> the html',
                },
            },
        };
    },
    computed: {
        runRemittance() {
            return [
                {
                    reportName: 'remittance',
                    reportParams: {
                        billPaymentRunId: this.formModel.billPaymentRunId,
                        vendorLink: {
                            id: this.formModel.vendorId, //'603862cb43431592f1da79e4',
                        },
                    },
                },
            ];
        },
    },
};
</script>
